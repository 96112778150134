.spinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #ddd;
  animation: spin 1s infinite linear;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3498db;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: bounce 1s infinite ease-in-out alternate;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  from,
  to {
    transform: translate(-50%, -50%);
    animation-timing-function: ease-in-out;
  }
  50% {
    transform: translate(-50%, -40%);
    animation-timing-function: ease-out;
  }
}
