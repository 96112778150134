@tailwind base;
@tailwind components;
@tailwind utilities;

#debug,
div {
  /*border: 1px solid deeppink;*/
}

html,
body {
  background: #f5faff;
  width: 100vw;
  height: 100vh;
  /*background: #090f23;*/
}

@layer base {
  body {
    @apply font-secondary text-secondary bg-gradient-to-b from-[#F6FAFD] to-[#FFFFFF] bg-no-repeat;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-primary text-primary;
  }
  a {
    @apply text-secondary font-semibold hover:text-accent-primary_hover transition;
  }
  .btn {
    @apply text-lg h-[60px] px-[30px] rounded-full transition text-white;
  }
  .btn-primary {
    @apply bg-accent-primary hover:bg-accent-primary_hover;
  }
  .btn-secondary {
    @apply bg-accent-secondary hover:bg-accent-secondary_hover;
  }
  .btn-quaternary {
    @apply bg-white text-base font-bold text-primary drop-shadow-primary hover:bg-accent-primary_hover hover:text-white;
  }
  .h1 {
    @apply text-[40px] xl:text-[80px] font-bold leading-[1.1];
  }
  .h2 {
    @apply text-[42px] font-extrabold leading-tight text-tertiary;
  }
  .h3 {
    @apply text-3xl font-bold text-baseBlue500;
  }
  .h4 {
    @apply text-2xl font-bold text-baseBlue500 tracking-wider;
  }
  .h6 {
    @apply text-xl font-normal text-baseTonBlue900;
  }
  .lead {
    @apply text-lg text-secondary font-secondary font-medium leading-relaxed;
  }
  .note-text-light {
    @apply text-sm text-baseStateWarning500 font-light;
  }
  .regular-text {
    @apply text-[17px] text-white font-normal;
  }
  .bold-text {
    @apply text-[17px] text-tertiary font-bold;
  }
  .subtitle-extra-bold-text {
    @apply text-[18px] text-tertiary font-extrabold;
  }
  .small-caption-text {
    @apply text-[12px] text-grayTone700 font-normal;
  }
}

.radio input {
  visibility: hidden;
  position: absolute;
}

.radio label:hover {
  cursor: pointer;
}

input:checked + label {
  border-color: #91a1b0;
  background-color: #cce8ff;
  font-weight: 700;
}

.flip-horizontal {
  transform: scaleX(-1);
}

.toggle label {
  @apply h-6 relative;
}

.toggle [type='checkbox'] {
  @apply w-11 h-0 cursor-pointer inline-block;
  @apply focus:outline-0 dark:focus:outline-0;
  @apply border-0 dark:border-0;
  @apply focus:ring-offset-transparent dark:focus:ring-offset-transparent;
  @apply focus:ring-transparent dark:focus:ring-transparent;
  @apply focus-within:ring-0 dark:focus-within:ring-0;
  @apply focus:shadow-none dark:focus:shadow-none;

  @apply after:absolute before:absolute;
  @apply after:top-0 before:top-0;
  @apply after:block before:inline-block;
  @apply before:rounded-full after:rounded-full;

  @apply after:content-[''] after:w-5 after:h-5 after:mt-0.5 after:ml-0.5;
  @apply after:shadow-md after:duration-100;

  @apply before:content-[''] before:w-10 before:h-full;
  @apply before:shadow-[inset_0_0_#000];

  @apply after:bg-white dark:after:bg-gray-50;
  @apply before:bg-gray-300 dark:before:bg-gray-600;
  @apply before:checked:bg-[#36C22A] dark:before:checked:bg-[#36C22A];
  @apply checked:after:duration-300 checked:after:translate-x-4;

  @apply disabled:after:bg-opacity-75 disabled:cursor-not-allowed;
  @apply disabled:checked:before:bg-opacity-40;
}

/*label {*/
/*  position: absolute;*/
/*  font-size: 14px;*/
/*  margin-left: 17px;*/
/*  margin-top: 1px;*/
/*}*/

.home-card-container {
  border: none;
  border-radius: 1rem;
  box-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  opacity: 1;
  min-height: 386px;
  width: 33%;
}

/* $ {
  ismobile? 'w-full' : 'w-[350px] h-[386px]';
} */

@media screen and (max-width: 768px) {
  .home-card-container {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .home-card-container {
    width: 50%;
  }
}
